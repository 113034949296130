import * as React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/Seo";
import { Header, Footer } from "../components/layout/index";
import {
  Hero,
  KeyFigures,
  Features,
  Testimonials,
  References,
  BlogListing,
  Cta,
  KeyPoints,
} from "../components/sections/index";
import { urlFor } from "../utils";

const Hospitals = props => {
  const { data } = props;

  const page = (data || {}).hospitalsPage;
  const coverPictureURL = (page.hero && urlFor(page.hero.image)) || undefined; // let SEO component define default image if hero has no image

  return (
    <>
      <Seo
        title="Solution de téléexpertise et de e-RCP - Hôpitaux publics et ESPIC"
        description="Tous les services hospitaliers réalisent chaque jour de nombreuses téléexpertises informelles. Omnidoc leur permet de simplifier, de sécuriser et de valoriser cette activité. La téléexpertise permet de renforcer les liens avec les médecins du territoire, de mieux trier et prioriser les demandes de prise en charge et d'améliorer la pertinence des parcours de soin."
        keywords="téléexpertise médicale rémunérée télé-expertise teleexpertise tele-expertise expertise acte avis télé-dermatologie télédermatologie télé-cardiologie télécardiologie télémédecine télé-médecine assurance maladie hôpitaux centres hospitaliers espic"
        image={coverPictureURL}
      />
      <Header />
      {page.hero && (
        <Hero
          title={page.hero.title}
          accentTitle={page.hero.accentTitle}
          subtitle="Hôpitaux publics et ESPIC"
          description={page.hero.description}
          image={page.hero.image}
          buttons={[
            {
              text: "Demander une démo",
              href: "/demander-une-demo",
            },
          ]}
        />
      )}
      {page.keyFiguresSection && (
        <KeyFigures
          header={page.keyFiguresSection.title}
          keyFigures={[
            page.keyFiguresSection.keyFigure1,
            page.keyFiguresSection.keyFigure2,
            page.keyFiguresSection.keyFigure3,
          ]}
        />
      )}
      {page.keyPointsSection && (
        <KeyPoints
          title={page.keyPointsSection.title}
          description={page.keyPointsSection.description}
          keyPoints={[
            page.keyPointsSection.keyPoint1,
            page.keyPointsSection.keyPoint2,
            page.keyPointsSection.keyPoint3,
          ]}
        />
      )}
      {page.references && (
        <References
          header={page.references.title}
          references={page.references}
        />
      )}
      {page.featuresSection && (
        <Features
          title={page.featuresSection.title}
          description={page.featuresSection.description}
          features={page.featuresSection.features}
        />
      )}
      {page.deploymentSection && (
        <KeyPoints
          title={page.deploymentSection.title}
          description={page.deploymentSection.description}
          className="bg-white"
          keyPoints={[
            page.deploymentSection.keyPoint1,
            page.deploymentSection.keyPoint2,
            page.deploymentSection.keyPoint3,
          ]}
        />
      )}
      {page.testimonials && page.testimonials.length > 0 && (
        <Testimonials testimonials={page.testimonials} />
      )}
      {!!(page.blogListingSection && page.blogListingSection.length) && (
        <BlogListing blog_posts={page.blogListingSection} />
      )}
      {page.ctaBanner && (
        <Cta
          titleLine1={page.ctaBanner.titleLine1}
          titleLine2={page.ctaBanner.titleLine2}
          description={page.ctaBanner.description}
          buttonText={page.ctaBanner.buttonText}
          url={page.ctaBanner.url}
        />
      )}
      <Footer />
    </>
  );
};

export default Hospitals;

export const query = graphql`
  query hospitalsPageQuery {
    hospitalsPage: sanityHospitalsPage(
      _id: { regex: "/(drafts.|)hospitalsPage/" }
    ) {
      hero {
        title
        accentTitle
        description
        image {
          ...Image
          alt
        }
      }
      keyPointsSection {
        title
        description
        keyPoint1 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
        keyPoint2 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
        keyPoint3 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
      }
      references {
        title
        logos {
          ...Image
          alt
          url
        }
      }
      featuresSection {
        title
        description
        features {
          title
          _rawDescription
          image {
            ...Image
            alt
          }
        }
      }
      keyFiguresSection {
        title
        keyFigure1 {
          number
          label
        }
        keyFigure2 {
          number
          label
        }
        keyFigure3 {
          number
          label
        }
      }
      deploymentSection {
        title
        description
        keyPoint1 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
        keyPoint2 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
        keyPoint3 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
      }
      testimonials {
        quote
        authorName
        authorTitle
        image {
          ...Image
          alt
        }
      }
      ctaBanner {
        titleLine1
        titleLine2
        description
        buttonText
        url
      }
      blogListingSection {
        id
        title
        slug {
          current
        }
        body: _rawBody
        description
        publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
        mainImage {
          ...ImageWithPreview
          alt
        }
      }
    }
  }
`;
