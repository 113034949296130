import * as React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/Seo";
import { Header, Footer } from "../components/layout/index";
import {
  Hero,
  KeyFigures,
  Features,
  Testimonials,
  References,
  BlogListing,
  Partners,
  Cta,
  KeyPoints,
} from "../components/sections/index";
import { urlFor } from "../utils";

const CptsEss = props => {
  const { data } = props;

  const page = (data || {}).cptsEssPage;
  const mainPage = (data || {}).mainPage;
  const coverPictureURL = (page.hero && urlFor(page.hero.image)) || undefined; // let SEO component define default image if hero has no image

  return (
    <>
      <Seo
        title="Solution de téléexpertise et de e-RCP - CPTS et ESS"
        description="Omnidoc permet aux Equipes de Soins Spécialisés (ESS) et aux CPTS d'améliorer l'accès à des avis spécialisés et d'optimiser les parcours de soins sur leur territoire. Avec Omnidoc, vous mettez en place un canal de téléexpertise efficace, sécurisé et rémunéré."
        keywords="téléexpertise médicale rémunérée télé-expertise teleexpertise tele-expertise expertise acte avis télé-dermatologie télédermatologie télé-cardiologie télécardiologie télémédecine télé-médecine assurance maladie ctps ess"
        image={coverPictureURL}
      />
      <Header />
      {page.hero && (
        <Hero
          title={page.hero.title}
          accentTitle={page.hero.accentTitle}
          subtitle="CPTS et ESS"
          description={page.hero.description}
          image={page.hero.image}
          buttons={[
            {
              text: "Demander une démo",
              href: "/demander-une-demo",
            },
          ]}
        />
      )}
      {page.keyFiguresSection && (
        <KeyFigures
          header={page.keyFiguresSection.title}
          keyFigures={[
            page.keyFiguresSection.keyFigure1,
            page.keyFiguresSection.keyFigure2,
            page.keyFiguresSection.keyFigure3,
          ]}
        />
      )}
      {page.keyPointsSection && (
        <KeyPoints
          title={page.keyPointsSection.title}
          description={page.keyPointsSection.description}
          keyPoints={[
            page.keyPointsSection.keyPoint1,
            page.keyPointsSection.keyPoint2,
            page.keyPointsSection.keyPoint3,
          ]}
        />
      )}
      {page.references && (
        <References
          header={page.references.title}
          references={page.references}
        />
      )}
      {page.featuresSection && (
        <Features
          title={page.featuresSection.title}
          description={page.featuresSection.description}
          features={page.featuresSection.features}
        />
      )}
      {page.testimonials && page.testimonials.length > 0 && (
        <Testimonials testimonials={page.testimonials} />
      )}
      {!!(page.blogListingSection && page.blogListingSection.length) && (
        <BlogListing blog_posts={page.blogListingSection} />
      )}
      {mainPage.partners && (
        <Partners
          title={mainPage.partners.title}
          description={mainPage.partners.description}
          partners={mainPage.partners}
        />
      )}
      {page.ctaBanner && (
        <Cta
          titleLine1={page.ctaBanner.titleLine1}
          titleLine2={page.ctaBanner.titleLine2}
          description={page.ctaBanner.description}
          buttonText={page.ctaBanner.buttonText}
          url={page.ctaBanner.url}
        />
      )}
      <Footer />
    </>
  );
};

export default CptsEss;

export const query = graphql`
  query cptsEssPageQuery {
    cptsEssPage: sanityCptsEssPage(_id: { regex: "/(drafts.|)cptsEssPage/" }) {
      hero {
        title
        accentTitle
        description
        image {
          ...Image
          alt
        }
      }
      keyPointsSection {
        title
        description
        keyPoint1 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
        keyPoint2 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
        keyPoint3 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
      }
      references {
        title
        logos {
          ...Image
          alt
          url
        }
      }
      featuresSection {
        title
        description
        features {
          title
          _rawDescription
          image {
            ...Image
            alt
          }
        }
      }
      keyFiguresSection {
        title
        keyFigure1 {
          number
          label
        }
        keyFigure2 {
          number
          label
        }
        keyFigure3 {
          number
          label
        }
      }
      testimonials {
        quote
        authorName
        authorTitle
        image {
          ...Image
          alt
        }
      }
      ctaBanner {
        titleLine1
        titleLine2
        description
        buttonText
        url
      }
      blogListingSection {
        id
        title
        slug {
          current
        }
        body: _rawBody
        description
        publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
        mainImage {
          ...ImageWithPreview
          alt
        }
      }
    }
    mainPage: sanityMainPage(_id: { regex: "/(drafts.|)mainPage/" }) {
      partners {
        title
        description
        logos {
          ...Image
          alt
          url
        }
      }
    }
  }
`;
